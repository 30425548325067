import { default as benchmarksZbx6Ozx93Meta } from "/vercel/path0/pages/benchmark.vue?macro=true";
import { default as calculatoryWuDZccaB9Meta } from "/vercel/path0/pages/calculator.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as dividendszeVBqieWlwMeta } from "/vercel/path0/pages/dividends.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as password_45reset6pLgh9teUjMeta } from "/vercel/path0/pages/password-reset.vue?macro=true";
import { default as portfolioek2jrdhiX7Meta } from "/vercel/path0/pages/portfolio.vue?macro=true";
import { default as benchmarkeBBRPgMAh6Meta } from "/vercel/path0/pages/public/benchmark.vue?macro=true";
import { default as purchaseen8RoHHJE3Meta } from "/vercel/path0/pages/purchase.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as screenerjECYKBKRlVMeta } from "/vercel/path0/pages/screener.vue?macro=true";
import { default as accountujSiEcXIUQMeta } from "/vercel/path0/pages/settings/account.vue?macro=true";
import { default as import_45dataPo0db17keCMeta } from "/vercel/path0/pages/settings/import-data.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as calculator91mYUr4AUoMeta } from "/vercel/path0/pages/tools/calculator.vue?macro=true";
import { default as unsubscribedZE1ohsoC9eMeta } from "/vercel/path0/pages/unsubscribed.vue?macro=true";
import { default as valuezOZVnQ3UQrMeta } from "/vercel/path0/pages/value.vue?macro=true";
export default [
  {
    name: "benchmark",
    path: "/benchmark",
    meta: benchmarksZbx6Ozx93Meta || {},
    component: () => import("/vercel/path0/pages/benchmark.vue").then(m => m.default || m)
  },
  {
    name: "calculator",
    path: "/calculator",
    meta: calculatoryWuDZccaB9Meta || {},
    component: () => import("/vercel/path0/pages/calculator.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dividends",
    path: "/dividends",
    meta: dividendszeVBqieWlwMeta || {},
    component: () => import("/vercel/path0/pages/dividends.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset6pLgh9teUjMeta || {},
    component: () => import("/vercel/path0/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: portfolioek2jrdhiX7Meta || {},
    component: () => import("/vercel/path0/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: "public-benchmark",
    path: "/public/benchmark",
    meta: benchmarkeBBRPgMAh6Meta || {},
    component: () => import("/vercel/path0/pages/public/benchmark.vue").then(m => m.default || m)
  },
  {
    name: "purchase",
    path: "/purchase",
    meta: purchaseen8RoHHJE3Meta || {},
    component: () => import("/vercel/path0/pages/purchase.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "screener",
    path: "/screener",
    meta: screenerjECYKBKRlVMeta || {},
    component: () => import("/vercel/path0/pages/screener.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-account",
    path: "account",
    component: () => import("/vercel/path0/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-import-data",
    path: "import-data",
    component: () => import("/vercel/path0/pages/settings/import-data.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tools-calculator",
    path: "/tools/calculator",
    meta: calculator91mYUr4AUoMeta || {},
    component: () => import("/vercel/path0/pages/tools/calculator.vue").then(m => m.default || m)
  },
  {
    name: "unsubscribed",
    path: "/unsubscribed",
    meta: unsubscribedZE1ohsoC9eMeta || {},
    component: () => import("/vercel/path0/pages/unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: "value",
    path: "/value",
    meta: valuezOZVnQ3UQrMeta || {},
    component: () => import("/vercel/path0/pages/value.vue").then(m => m.default || m)
  }
]